import { Component, EventEmitter,Input, Output, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-pop-ups',
  templateUrl: './pop-ups.component.html',
  styleUrls: ['./pop-ups.component.scss']
})
export class PopUpsComponent implements OnInit{
  @Input() props: any = ''
  @Input() isRadioButtonNeeded:boolean=false;
  @Input() control= new FormControl('',[Validators.required]);
  @Output() downloadConfirmed: EventEmitter<any> = new EventEmitter();
  @Input() isCancelButtonRequired:boolean=false;
  @Output() cancelConfirmed: EventEmitter<any> = new EventEmitter();
  @Input() isAbortOnlyOptionEnabled:boolean = false;
constructor(public modal: ModalService){

}
ngOnInit(): void {
}

oncancel(){
this.modal.toggleModal(this.props.id)
}
onConfirmDownload(){
  if(this.control.value!='Postman project'){
    this.modal.toggleModal(this.props.id);
    this.downloadConfirmed.emit(this.control.value);
  }
}
onCancelConfirm(){
  this.modal.toggleModal(this.props.id);
  this.cancelConfirmed.emit(true);
}
}
