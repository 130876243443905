
export class ApiConstants {
  static API_GetProductsAndApis = '/api/Product/GetProductsAndApis';
  static API_Publisher_GetProductsAndApis = '/api/Publisher/GetProducts';
  static API_Publisher_ProductSubcription = '/api/Publisher/GetSubscriptionsFromApim';
  static API_Publisher_GetAllProducts = '/api/Developer/GetAllProducts';
  static API_GetTotalProducts = '/api/Product/GetTotalProducts';
  static API_GetApisByProductId = '/api/Product/GetApisByProductId';
  static API_GetApisByApimProductId = '/api/Publisher/GetApisByProductId';
  static getSubscriptionApisByApimProductId = '/api/Publisher/GetApisByProductId';
  static getDeveloperSubscriptionApisByApimProductId = '/api/Developer/GetApisByProductId';
  static getApisAndSubscriptionAttributeByProductId = '/api/Publisher/GetApisAndSubscriptionAttributeByProductId'
  static API_GetSubscriptionsStatistics = '/api/publisher/GetSubscriptionsStatistics';
  static API_Developer_GetSubscriptionsStatistics = '/api/Developer/GetSubscriptionsStatistics';
  static API_GetProductsByApi = '/api/Product/GetProductsByApi';
  static API_CreateProduct = '/api/product';
  static API_UpdateProduct = '/api/product';
  static API_DeleteProduct = '/api/Product';

  static API_CreateApi = '/api/Api';
  static API_UpdateApi = '/api/Api'; 
  static API_DeleteApi = '/api/Api';
  static API_GetTotalApis = '/api/Api/GetTotalApisCount';
  static API_GetApis = '/api/Publisher/GetApis';
  static API_GetSubscriptionApprovalList = '/api/Publisher/GetSubscriptionsApprovalsList';
  static API_GetAllApimApis = '/api/Publisher/GetAllApimApis';
  static API_GetAllApimTags = '/api/Publisher/GetAllTags';
  static API_GetApiByName = '/api/Api/GetApi'; 

 
  static API_GetApimApisByPublisher='/api/Publisher/GetApi'
  static API_GetApiOperations = '/api/Publisher/GetApiOperations';
  static API_GetApimProductsByApi = '/api/Publisher/GetApimProductsByApi';
  static API_GetProductsByProductId = '/api/Publisher/GetProductsByProductId'
  static API_UpsertApi = '/api/Publisher/UpsertApi';
  static API_EditAPI = '/api/Publisher/EditApi';
  static API_AddProduct = '/api/Publisher/AddProduct';
  static API_AddDraftProduct = '/api/Publisher/AddDraftProduct';
  static API_SaveDraftProduct = '/api/Publisher/EditDraftProduct';
  static API_UpdateSubscriptionAttribute = '/api/Publisher/UpdateSubscriptionAttribute';
  static API_ApproveSubscription = '/api/subscription/ApproveProductSubscription';
  static API_EditProduct = '/api/Publisher/EditProduct';
  static API_ViewProductPublisherSection = '/api/Publisher/GetProductsByProductId';
  static API_ViewProductDeveloperSection = '/api/Developer/GetProductsByProductId';
  static API_ProductTags = '/api/Developer/GetTagsByProductId';
  static API_Publisher_ProductTags = "/api/Publisher/GetTagsByProductId";
  static API_DeleteApimProduct = '/api/Publisher/DeleteProduct';
  static API_DeleteApimApi = '/api/Publisher/DeleteApi';
  static API_DeleteApimSubscription = '/api/Publisher/DeleteSubscription';
  static API_GetApimProductsAndOwnersByApiId='/api/Publisher/GetApimProductsAndOwnerDetailsByApi'

  static API_Get_AdApplicationList ='/api/AppRegistration/GetListOfADApplications'
  static API_Get_IsValidCmdbOwner ='/api/cmdb/ValidateCmdbOwner';
  static API_Get_ApplicationsByUser ='/api/developer/GetApplicationsByUser';
  static API_GetADApplicationById ='/api/AppRegistration/GetADApplicationById'
  static API_Get_AdUsersList ='/api/AppRegistration/GetListOfADUsers';
  static API_GetListOfADUsers_Owners ='/api/AppRegistration/GetListOfADUsers_Owners';
  static API_GetListOfADUsers_Approvers ='/api/AppRegistration/GetListOfADUsers_Approvers';
  static API_Get_CmdbApplicationList ='/api/cmdb/GetApplications'
  static API_Get_CmdbDetails ='/api/cmdb/GetDetails'
  static API_Get_HIPEligibleApplication ='/api/AppRegistration/GetHIPEligibleApplication'
  static API_Health = '/sys/health';
  
  static API_GetApimApisByProduct = '/api/Apim/GetApimApisByProduct';
  static API_GetApiSpecification ='/api/Apim/GetApiSpecification';

  static API_GetApiCmdbGetDetails ='/api/Cmdb/GetDetails';
  static API_GetApiCmdbGetApplications ='/api/Cmdb/GetApplications';

  static API_GetApiAppRegistrationGetListOfADApplications ='/api/AppRegistration/GetListOfADApplications';
  static API_GetApiAppRegistrationValidateAndListAppOwners='/api/AppRegistration/ValidateAndListAppOwners';
  static API_AppRegistrationADGroup='/api/AppRegistration/AddOwnersInADGroup';
  static API_GetApplicationOwnersWithValidation='/api/AppRegistration/GetApplicationOwnersWithValidation';

  static API_GetApiAppRegistrationGetListOfADUsers='/api/AppRegistration/GetListOfADUsers';
  static API_GetNonHIPApplications ='/api/AppRegistration/GetNonHIPApplications';
  

    
  static API_GetApiSubscriptionGetSubscriptionsbyUserEmail='/api/Subscription/GetSubscriptionsbyUserEmail';
  static API_GetApiSubscriptionGetSubscriptionBySubscriptionName='/api/Publisher/GetSubscriptionBySubscriptionName';
  static API_GetDeveloperSubscriptionBySubscriptionName='/api/Developer/GetSubscriptionBySubscriptionName';
  static API_GetPendingApprovalDetails='/api/Publisher/GetPendingApprovalDetails';
  static API_GetSubscriptionsbyUser='/api/Subscription/GetSubscriptionsbyUser';
  static API_GetApiSubscriptionGetSubscriptionKey='/api/Subscription/GetSubscriptionKey';
  static API_GetApiSubscriptionGetApisForSubscription='/api/Subscription/GetApisForSubscription';
  static API_GetApiSubscriptionGetSubscriptionsByApi='/api/Subscription/GetSubscriptionsByApi';
  static API_createSubscriptionApi='/api/Subscription';
  static API_NotifySubscriptionApi='/api/subscription/NotifySubscription';
  static API_putRejectSubscriptionApi='/api/Subscription/Reject';
  static API_approveProductSubscriptionApi='/api/Subscription/Subscription/ApproveProductSubscription'
  static API_GetSubscriptionsByApplicationOwner='/api/Subscription/GetSubscriptionsByApplicationOwner';
  static API_GetDownloadFileForSubscription = '/api/Subscription/DownloadHttpFile';
  static API_GetSubscriptionsByCmdbId='/api/Developer/GetSubscriptionsByCmdbId';
  static API_putApiApimTracing = '/api/apim/Tracing';
  static API_GetSubscriptionsToBeExported = '/api/Publisher/GetSubscriptionsToBeExported'
  static API_putCancelSubscriptionApi='/api/Developer/CancelSubscription';
  static API_putReactivateSubscriptionApi='/api/Developer/ReactivateSubscription';
  static API_DeleteSubscriptionApi='/api/Developer/DeleteSubscription';
  static API_DeleteSubscriptionPublisherApi='/api/Publisher/DeleteSubscription';
  static API_GetMySubscriptionsToBeExported = '/api/Developer/GetMySubscriptionsToBeExported'
  static API_GetDeveloperManageSubscriptionsToBeExported = '/api/Developer/GetManageSubscriptionsToBeExported'
  static API_ValidateSubscriptionAttributesForAPIs='/api/SubscriptionAttributes/ValidateSubscriptionAttributesForAPIs';
  static API_PutPublishingOrUnpublishingProduct='/api/Publisher/PublishingOrUnpublishingProduct';
  static API_PutPublisherCancelSubscription='/api/Publisher/CancelSubscription';
  static API_PutPublisherReactivateSubsciption='/api/Publisher/ReactivateSubscription';
  static API_GetSubscriptionsTotalCount="/api/Publisher/GetSubscriptionsTotalCount"
}
