<div class="prod_card">
    <div class="flexContainer">
        <div class="businessTag" [ngClass]="{'cyan': this.dataset.businessArea === 'CT', 'atlasBlue': this.dataset.businessArea === 'CO', 'cometRed': this.dataset.businessArea === 'IT', 'atlasViolet': this.dataset.businessArea === 'PT', 'atlasOrange': this.dataset.businessArea === 'VT'}"><span class="middle">{{this.dataset.businessArea}}</span></div>
        <div class="cardDetail" [class.cardDetail-single-card]="showSingleCardStyle">
            <h3>{{this.dataset.productName}}</h3>
            <p>{{this.dataset.description}}</p>
            <app-render-control-values [value]="this.dataset.apitags" [type]="'tag'" [isClickableLabel]="isClickableLabelReq" [isTagLabel]=true (tagClickEvent)="onTagSearch($event)"></app-render-control-values>
        </div>
        <div title="Active subscriptions" style="cursor: default;justify-items: center;" *ngIf="showSubscriptionCount">
            <img src="../../../../assets/sideNav/active-sub-count.png" style="width: 2.4rem;">
            <h4>{{activeSubCount}}</h4>
        </div>
    </div>
    <div class="apiWrappers" *ngIf="enableAPIQueryParams">
        <ng-container *ngFor="let api of this.dataset.apiData" >
            <a [routerLink]="api.apiLink" class="link_secondary" [queryParams]="{'apiName': api.name, 'productId':this.dataset.productID, 'productName':this.dataset.productName}">{{api.name}}</a>
        </ng-container>
        
    </div>
    <div class="apiWrappers" *ngIf="!enableAPIQueryParams">
        <ng-container *ngFor="let api of this.dataset.apiData" >
            <a [routerLink]="api.apiLink" class="link_secondary" (click)="onApiClick(api.apimApiName,api.name)">{{api.name}}</a>
        </ng-container>        
    </div>
</div>
